














import { Vue, Component } from "vue-property-decorator";
import { DateText } from "ui-gallery";
/**
 * 接続履歴を一覧表示するためのアイテムを提供します.
 */
@Component({ components: { DateText } })
export default class HistoryItem extends Vue {
    rating = Math.floor(Math.random() * 10) % 5;
}
