













































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import HistoryItem from "@/components/Managements/History/HistoryItem.vue";
import { IHistory, IHistoryDetail } from "@/models/histories/IHistory";
import HistoryDetailDrawer from "./DetailDrawer.vue";
import Enumrable from "linq";
import Axios from "axios";
import { SuccessResponse } from "../../../models/api/Response";
import { DateTime, Duration } from "luxon";
import { AuthService } from "@/models/auth/AuthService";
import { container } from "tsyringe";

const headers = [
    { text: "", value: "thread", sortable: false, width: 26 },
    { text: "No", value: "historyId", width: 80 },
    { text: "ルーム名", value: "name", width: 140 },
    { text: "作成者", value: "createdBy", width: 140 },
    { text: "開始時間", value: "startedAt", width: 140 },
    { text: "終了時間", value: "completedAt", width: 140 },
    { text: "会議時間", value: "duration", width: 140 },
    { text: "メッセージ数", value: "sharedFileCount", width: 140 }
];

/**
 * 接続履歴を表示するためのページを提供します.
 */
@Component({ components: { HistoryItem, HistoryDetailDrawer } })
export default class HistoryPage extends Vue {
    private readonly displayResultsCount = 60;
    private isLoading = false;
    private tableHeight = 0;
    private isShowSearchPanel = false;
    private menu = false;
    private menu2 = false;
    private headers = headers;
    private openedThreadId = 0;
    private selected: IHistory | null = null;

    private histories: IHistory[] = [];
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private pageCount = 1;
    private totalVisible = 5;
    private page = 1;
    private hitCount = 0;

    private memberId = null;
    private endedFrom = DateTime.local().minus(Duration.fromObject({ week: 2 })).toFormat("yyyy-MM-dd");
    private endedTo = DateTime.local().toFormat("yyyy-MM-dd");
    private readonly authService = container.resolve(AuthService);

    get members() {
        return Object.keys(this.authService.members).map((x: any) => ({ name: this.authService.members[x], id: x }));
    }

    format(dateStr: string) {
        return dateStr ? DateTime.fromISO(dateStr).toFormat("y年MM月dd日 HH時mm分ss秒") : "---";
    }

    diff(date1: string, date2: string) {
        return DateTime.fromISO(date2).diff(DateTime.fromISO(date1)).toFormat("hh時間mm分ss秒");
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private created() {
        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 164;
        });
        this.tableHeight = window.innerHeight - 164;
    }

    private activated() {
        this.fetchAsync();
    }

    @Watch("page")
    private onOptionsChanged() {
        this.fetchAsync();
    }

    private async fetchAsync() {
        try {
            this.isLoading = true;
            const end = new Date(this.endedTo);
            end.setHours(23, 59, 59, 999);
            const response = await Axios.get<SuccessResponse<{ count: number, histories: IHistory[] }>>("/api/connection-histories", {
                params: {
                    offset: (this.page - 1) * this.displayResultsCount,
                    limit: this.displayResultsCount,
                    endedFrom: new Date(this.endedFrom).toJSON(),
                    endedTo: end.toJSON(),
                    memberId: this.memberId
                }
            });
            this.histories = response.data.data.histories;

            this.hitCount = response.data.data.count;
            const n = this.hitCount / this.displayResultsCount;
            this.pageCount = Number.isInteger(n) ? n : Math.floor(this.hitCount / this.displayResultsCount) + 1;
            this.isLoading = false;
            return true;
        }
        catch (ex) {
            console.error("接続履歴の取り込みに失敗しました", ex);
            logger.error(ex);
        }
    }

    private async showDetail(item: IHistory): Promise<void> {
        const drawer = this.$refs.historyDetailDrawer as HistoryDetailDrawer | undefined;
        if (!drawer) return;
        this.selected = item;
        await drawer.showAsync(item);
        this.selected = null;
    }

    private async csvExport() {
        const end = new Date(this.endedTo);
        end.setHours(23, 59, 59, 999);
        const response = await Axios.get<SuccessResponse<{ count: number, histories: IHistory[] }>>("/api/connection-histories", {
            params: {
                endedFrom: new Date(this.endedFrom).toJSON(),
                endedTo: end.toJSON(),
                memberId: this.memberId
            }
        });

        const histories = response.data.data.histories;
        const lines: string[] = [];
        let count = response.data.data.count;
        lines.push(`No., メンバー, ルーム名, 開始日時, 終了日時, 接続時間`);
        for (const item of histories) {
            lines.push(`${count--}, ${this.authService.members[item.memberId]}, ${item.roomName}, ${DateTime.fromISO(item.startedAt).toFormat("y/MM/dd HH:mm:ss")}, ${DateTime.fromISO(item.endedAt).toFormat("y/MM/dd HH:mm:ss")}, ${DateTime.fromISO(item.endedAt).diff(DateTime.fromISO(item.startedAt)).toFormat("hh:mm:ss")}`);
        }
        const text = lines.join("\r\n");
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        var blob = new Blob(
            [bom, text],
            { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${DateTime.fromISO(this.endedFrom).toFormat("y_MM_dd_HH_mm_ss")}_${DateTime.fromISO(this.endedTo).toFormat("y_MM_dd_HH_mm_ss")}.csv`;
        link.click();
    }
}
