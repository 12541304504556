







































































import { Component, Vue, Mixins } from "vue-property-decorator";
import ManagementsMain from "@/components/Managements/ManagementsMain.vue";
import LoginPage from "@/components/Managements/Login/LoginPage.vue";
import { container } from "tsyringe";
import { DialogBase } from "ui-gallery";
import { AuthService } from "@/models/auth/AuthService";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { DateTime } from "luxon";

/**
 * 接続履歴の詳細を表示する画面を提供します．
 */
@Component({
    components: {
    }
})
export default class HistoryDetailDrawer extends Mixins(DialogBase) {
    format(dateStr: string) {
        return DateTime.fromISO(dateStr).toFormat("HH:mm");
    }
}
